import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				SparkleClean
			</title>
			<meta name={"description"} content={"Ваш дім, наш пріоритет"} />
			<meta property={"og:title"} content={"SparkleClean"} />
			<meta property={"og:description"} content={"Ваш дім, наш пріоритет"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08:50:53.474Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-4.jpg?v=2024-05-27T08%3A50%3A53.474Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Ваш дім, наш пріоритет
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Ми розуміємо, що підтримання чистоти та порядку в домі може бути складним завданням серед вашого напруженого графіка. Саме тут ми виступаємо, пропонуючи першокласні послуги з прибирання дому, адаптовані до ваших конкретних потреб. У SparkleClean ми віримо, що чистий дім – це щасливий дім, і ми прагнемо надавати виняткові рішення для прибирання, щоб ваш життєвий простір виблискував.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							Наші послуги &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Досвідчені професіонали
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Наша команда складається з висококваліфікованих і досвідчених прибиральників, які пишаються своєю роботою та гарантують, що ваш дім буде бездоганно чистим.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0px 10%"
					width="40%"
					display="flex"
					justify-content="space-around"
					lg-margin="50px 0px 0px 0"
					lg-width="100%"
					lg-justify-content="flex-start"
					sm-flex-direction="column"
					sm-align-items="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="#cd9f55"
							border-color="#dca654"
							sm-text-align="left"
						>
							60+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							постійних партнерів
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" text-align="center">
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="#cd9f55"
							border-color="#dca654"
							sm-text-align="left"
						>
							400+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							задоволених клієнтів
						</Text>
					</Box>
				</Box>
			</Box>
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Екологічно чисті продукти
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				Ми надаємо пріоритет вашому здоров’ю та навколишньому середовищу, використовуючи лише екологічно чисті та нетоксичні миючі засоби.
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Індивідуальні плани прибирання
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				налаштуйте наші послуги відповідно до ваших конкретних потреб і вподобань, гарантуючи, що кожна частина вашого дому буде прибрана так, як ви захочете.
			</Text>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08:50:53.440Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						order="1"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-2.jpg?v=2024-05-27T08%3A50%3A53.440Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Вибір SparkleClean Services означає вибір якості, надійності та душевного спокою.  вашого дому буде приділено увагу, на яку він заслуговує.
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							У SparkleClean Services наша місія полягає в тому, щоб забезпечити бездоганне та комфортне середовище для вас і вашої родини. Маючи багаторічний досвід роботи в клінінговій індустрії, ми відточили свої навички та розробили ефективні методи прибирання, щоб впоратися з будь-яким безладом, великим чи малим. Наша команда професійних прибиральників ретельно навчена, перевірена та прагне досконалості. Ми пишаємося своєю роботою та прагнемо перевершити ваші очікування з кожним прибиранням.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							Контакти &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3" font="--headline2">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Огляд послуг
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				color="--dark"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Ми використовуємо екологічно чисті миючі засоби, які безпечні для вашого дому та родини. Наші гнучкі варіанти планування дозволяють вам замовляти наші послуги у зручний для вас час, незалежно від того, чи потрібна вам одноразова глибока чистка чи регулярне технічне обслуговування. Ми пропонуємо індивідуальні плани прибирання відповідно до вашого способу життя та вподобань, гарантуючи, що кожному куточку вашого дому буде приділено увагу, на яку він заслуговує.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08:50:53.458Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/3-4.jpg?v=2024-05-27T08%3A50%3A53.458Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Прибирання житла
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08:50:53.477Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/2-1%20%281%29.jpg?v=2024-05-27T08%3A50%3A53.477Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Прибирання при заїзді/виїзді
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08:50:53.448Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-1.jpg?v=2024-05-27T08%3A50%3A53.448Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="center">
						Спеціалізоване прибирання
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-red"
				font="normal 400 17px/1.5 --fontFamily-sans"
				href="/services"
				type="link"
				text-decoration-line="initial"
			>
				Всі послуги
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link3" />
			<Override slot="text3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});